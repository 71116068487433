import React, { useEffect, useRef } from 'react';
import './App.css';
import confetti from 'canvas-confetti';

function App() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true,
    });

    // Fire confetti with full-screen spread on page load
    myConfetti({
      particleCount: 150,
      spread: 160,
      startVelocity: 30,
      gravity: 0.5,
      origin: { x: 0.5, y: 0.5 },
      colors: ['#ff69b4', '#ff1493', '#ffc0cb', '#ff4500'],
      shapes: ['circle'],
    });
  }, []);

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true,
    });

    // Special confetti effect when button is clicked
    myConfetti({
      particleCount: 200,
      spread: 360,
      startVelocity: 50,
      gravity: 0.7,
      scalar: 1.2, // Make particles slightly larger
      ticks: 500,  // Particles last longer
      origin: { x: 0.5, y: 0.5 },
      colors: ['#FFD700', '#FF4500', '#FF1493', '#1E90FF'],
      shapes: ['star', 'square'], // Mixed shapes for effect
    });

    // Trigger file download
    const fileUrl = 'https://d1sax8z0tfd9ab.cloudfront.net/Resume/letter_protected.pdf';
    window.location.href = fileUrl;
  };

  return (
    <div className="container">
      <canvas ref={canvasRef} style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
        pointerEvents: 'none'
      }}></canvas>
      <h1>
        Happy Birthday! Fianceee <span role="img" aria-label="party popper">🎉</span><span role="img" aria-label="birthday cake">🎂</span>
      </h1>
      <p>
        With lots of love <span role="img" aria-label="red heart">❤️</span>
      </p>
      <p>Hint: Password is the name of our daughter followed by my birthday and your birthday number</p>
      <button onClick={handleDownload}>
        Download Your Gift <span role="img" aria-label="gift">🎁</span>
      </button>
    </div>
  );
}

export default App;
